<template>
  <div :class="{ 'web-header': true, 'en-font': language == 'en', 'cn-font': language == 'cn' }">
    <div class="web-header-top">
      <div class="web-header-top-box">
        <div class="web-header-top-box-card">
          <a href="https://www.cf40.org.cn/" target="_blank" class="web-header-top-box-card-link" v-if="language == 'cn'">
            <div class="web-header-top-box-card-link-icon">
              <img src="~@/assets/cf40.png" />
            </div>
            <span class="web-header-top-box-card-link-text">中国金融四十人论坛</span>
          </a>
          <a href="https://en.cf40.com/" target="_blank" class="web-header-top-box-card-link" v-else>
            <div class="web-header-top-box-card-link-icon">
              <img src="~@/assets/cf40.png" />
            </div>
            <span class="web-header-top-box-card-link-text roboto-light">CHINA FINANCE 40 FORUM</span>
          </a>
          <a href="/en" class="web-header-top-box-card-switch" v-if="language == 'cn'">
            EN
          </a>
          <a href="/" class="web-header-top-box-card-switch roboto-light" v-else>
            CN
          </a>
        </div>
      </div>
    </div>
    <div class="web-header-content">
      <div :class="{ 'web-header-content-box': true, 'is-login': info.mobile || info.email }">
        <a href="/" class="web-header-content-box-cn_logo" v-if="language == 'cn'">
          <img src="~@/assets/chinese_logo.png" alt="CF40 研究" />
        </a>
        <a href="/en" class="web-header-content-box-en_logo" v-else>
          <img src="~@/assets/english_logo.png" alt="CF40 RESEARCH" />
        </a>
        <div class="web-header-content-box-opera">
          <div class="web-header-content-box-opera-user" v-if="info.mobile || info.email">
            <el-dropdown size="medium" trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ info.mobile || info.email }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="logout">&nbsp;&nbsp;&nbsp;{{ language == 'en' ? 'Log Out' : '退  出' }}&nbsp;&nbsp;&nbsp;</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span class="web-header-content-box-opera-user-link cn-font-bold" @click="subscribe" v-if="language == 'cn'">订阅</span>
            <span class="web-header-content-box-opera-user-link roboto-bold" @click="subscribe" v-else>SUBSCRIBE</span>
          </div>
          <template v-else>
            <div class="web-header-content-box-opera-login cn-font-bold" @click="login" v-if="language == 'cn'">
              登录
            </div>
            <div class="web-header-content-box-opera-login roboto-bold" @click="login" v-else>
              SIGN IN
            </div>
            <div class="web-header-content-box-opera-subscribe cn-font-bold" @click="subscribe" v-if="language == 'cn'">
              订阅
            </div>
            <div class="web-header-content-box-opera-subscribe roboto-bold" @click="subscribe" v-else>
              SUBSCRIBE
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- 登录 -->
    <login-chinese ref="loginChineseRef" @success="loginSuccess"></login-chinese>
    <login-english ref="loginEnglishRef" @success="loginSuccess"></login-english>
    <!-- 订阅 -->
  </div>
</template>

<script>
import LoginChinese from "../loginChinese";
import LoginEnglish from "../loginEnglish";
import { userInfo, logoutSystem } from "@/api";
export default {
  name: "WebHeader",
  components: {
    LoginChinese,
    LoginEnglish
  },
  props: {
    language: {
      type: String,
      default: "en"
    }
  },
  data() {
    return {
      info: {}
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 登录
    login() {
      if (this.language == "cn") {
        this.$refs.loginChineseRef.open();
      } else {
        this.$refs.loginEnglishRef.open();
      }
    },
    // 判断是否重新加载当前页面
    reloadPage() {
      let links = window.location.href;
      if (links.indexOf("report/english") || links.indexOf("report/chinese")) {
        window.location.reload();
      } else if (links.indexOf("equity/english") || links.indexOf("equity/chinese")) {
        window.location.reload();
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        let res = await userInfo();
        if (res && res.code == 200) {
          this.info = res.data.user_info;
        }
      }
    },
    // 登录成功
    loginSuccess() {
      this.getUserInfo();
      this.reloadPage();
    },
    // 退出系统
    async logoutHandle() {
      let bool = false;
      let tip = "退出成功!";
      if (this.language == "en") {
        bool = true;
        tip = "Logout successfully!";
      }
      let res = await logoutSystem(bool);
      if (res && res.code == 200) {
        localStorage.clear();
        this.$message.success(res.msg ? res.msg : tip);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },
    // 登陆后点击下拉框的操作
    handleCommand(command) {
      if (command == "logout") {
        this.logoutHandle();
      }
    },
    // 订阅
    subscribe() {
      if (this.language == "cn") {
        this.routerLink('/equity/chinese', true);
      } else {
        this.routerLink('/equity/english', true);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.en-font {
  font-family: Georgia;
}
.en-font-bold {
  font-family: Georgia_Bold;
}
.cn-font {
  font-family: HarmonyOS_Sans_Regular;
}
.cn-font-bold {
  font-family: HarmonyOS_Bold;
}
.roboto-light {
  font-family: Roboto_Light;
}
.roboto-bold {
  font-family: Roboto_Bold;
}
.web-header {
  &-top {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #ebeef0;
    &-box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      &-card {
        display: flex;
        align-items: center;
        &-link {
          color: #757575;
          text-decoration: none;
          display: flex;
          align-items: center;
          &-icon {
            width: 30px;
            line-height: 0;
            display: inline-block;
          }
          &-text {
            margin: 0 20px 0 10px;
            color: #757575;
          }
        }
        &-switch {
          width: 26px;
          height: 26px;
          line-height: 26px;
          font-size: 14px;
          text-align: center;
          cursor: pointer;
          color: #ffffff;
          text-decoration: none;
          background-color: #ed6f33;
        }
      }
    }
  }
  &-content {
    padding: 30px 0;
    border-bottom: 1px solid #ededed;
    &-box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-cn_logo {
        max-width: 206px;
      }
      &-en_logo {
        max-width: 152px;
      }
      &-opera {
        display: flex;
        align-items: center;
        &-user {
          display: flex;
          align-items: center;
          &-link {
            margin-left: 15px;
            user-select: none;
            cursor: pointer;
            &:hover {
              color: #ed6f33;
            }
          }
        }
        &-login, &-subscribe {
          cursor: pointer;
          padding: 10px 20px;
          border-radius: 10px;
          border: 2px solid #ed6f33;
          user-select: none;
          &:hover {
            border-color: #f38a58;
            background-color: #f38a58;
          }
        }
        &-login {
          color: #ed6f33;
          &:hover {
            color: #ffffff;
          }
        }
        &-subscribe {
          color: #ffffff;
          margin-left: 30px;
          background-color: #ed6f33;
        }
        ::v-deep {
          .el-dropdown {
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .web-header {
    &-top {
      &-box {
        width: calc(100% - 60px);
        padding: 0 30px;
      }
    }
    &-content {
      &-box {
        width: calc(100% - 60px);
        padding: 0 30px;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .web-header {
    &-top {
      &-box {
        width: calc(100% - 60px);
        padding: 0 30px;
      }
    }
    &-content {
      &-box {
        width: calc(100% - 60px);
        padding: 0 30px;
        &.is-login {
          flex-direction: column;
        }
        &-cn_logo {
          max-width: 150px;
        }
        &-en_logo {
          max-width: 100px;
        }
        &-opera {
          &-user {
            margin-top: 10px;
            display: flex;
            align-items: center;
          }
          &-login, &-subscribe {
            padding: 8px 12px;
            font-size: 14px;
          }
          &-subscribe {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>